<template>
  <div class="view" v-html="detail.content"></div>
</template>

<script>
export default {
  name: "",
  components: {},

  props: {},

  data() {
    return {
      id: null,
      detail: {},
    };
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },

  methods: {
    async getDetail() {
      const res = await this.$api.course.getCourseDetail(this.id);
      this.detail = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.view {
}
</style>
